<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <g clip-path="url(#clip0_6043_6460)">
      <rect
        width="20.8333"
        height="20.8333"
        transform="translate(0.166504 0.0602264)"
        fill="#160535"
      />
      <path
        d="M14.4894 5.26856V5.91961M14.4894 8.52377V9.17482M14.4894 11.779V12.43M14.4894 15.0342V15.6852M6.67692 11.1279H11.2342M6.67692 13.0811H9.28109M3.09619 4.61752C2.55685 4.61752 2.11963 5.05474 2.11963 5.59409V8.22112C2.89802 8.6714 3.42171 9.51299 3.42171 10.4769C3.42171 11.4408 2.89802 12.2824 2.11963 12.7327V15.3597C2.11963 15.8991 2.55685 16.3363 3.09619 16.3363H18.0702C18.6095 16.3363 19.0467 15.8991 19.0467 15.3597V12.7327C18.2683 12.2824 17.7446 11.4408 17.7446 10.4769C17.7446 9.51299 18.2683 8.6714 19.0467 8.22112V5.59409C19.0467 5.05475 18.6095 4.61752 18.0702 4.61752H3.09619Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6043_6460">
        <rect
          width="20.8333"
          height="20.8333"
          fill="white"
          transform="translate(0.166504 0.0602264)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Ticket',
};
</script>
