<template>
  <div class="border-b border-ol-black py-8">
    <h3 class="text-[36px] leading-[56px] mb-4">
      <slot name="title"></slot>
    </h3>
    <div class="flex flex-col gap-y-4 text-[24px] leading-[32px] text-ol-black">
      <slot name="body"></slot>
    </div>
  </div>
</template>
