<template>
  <svg
    width="30"
    height="25"
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8 2.76226C25.7333 3.82893 24.7467 5.10893 23.84 6.60226C22.9867 8.0956 22.4267 9.58893 22.16 11.0823C24.1333 11.0823 25.76 11.6423 27.04 12.7623C28.3733 13.8823 29.04 15.4556 29.04 17.4823C29.04 19.4556 28.4267 21.1089 27.2 22.4423C26.0267 23.7223 24.48 24.3623 22.56 24.3623C20.64 24.3623 19.0667 23.6956 17.84 22.3623C16.6667 20.9756 16.08 19.2956 16.08 17.3223C16.08 14.0156 16.88 10.8423 18.48 7.80226C20.08 4.70893 22.1067 2.30893 24.56 0.602264L26.8 2.76226ZM11.36 2.76226C10.2933 3.82893 9.30667 5.10893 8.4 6.60226C7.54667 8.0956 6.98667 9.58893 6.72 11.0823C8.69333 11.0823 10.32 11.6423 11.6 12.7623C12.9333 13.8823 13.6 15.4556 13.6 17.4823C13.6 19.4556 12.9867 21.1089 11.76 22.4423C10.5867 23.7223 9.04 24.3623 7.12 24.3623C5.2 24.3623 3.62667 23.6956 2.4 22.3623C1.22667 20.9756 0.64 19.2956 0.64 17.3223C0.64 14.0156 1.44 10.8423 3.04 7.80226C4.64 4.70893 6.66667 2.30893 9.12 0.602264L11.36 2.76226Z"
      fill="#7457E8"
    />
  </svg>
</template>
