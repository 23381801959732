import debounce from '../utils/debounce';

export const isMobile = {
  data() {
    return {
      isMobile: null,
    };
  },
  mounted() {
    window.addEventListener('resize', debounce(this.onResize));
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 1024;
    },
  },
};

export default isMobile;
