<template>
  <div
    class="p-5 flex gap-y-2 mb-6 flex-col md:flex-row border-2 rounded-2xl justify-between"
  >
    <div class="flex flex-col gap-y-2 lg:flex-row md:my-auto md:gap-x-3">
      <h4 class="font-bold md:text-xl">{{ role.title }}</h4>
      <span class="text-sm md:text-lg text-ol-ashes">{{ role.location }}</span>
    </div>
    <section
      class="flex flex-col sm:flex-row flex-end gap-y-2 gap-x-4 bg-transparent"
    >
      <OLButton
        @click.native="findOutMore"
        size="small"
        colour="cream"
        variant="secondary"
        class="flex justify-center my-auto gap-2 w-full md:w-auto"
        >Find out more
      </OLButton>
      <OLButton
        @click.native="gotToJob(role.providerUrl)"
        size="small"
        colour="special"
        variant="primary"
        class="flex my-auto justify-center gap-2 w-full md:w-auto"
        >Apply
        <Arrow></Arrow>
      </OLButton>
    </section>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import OLButton from '@js/components/Button/OLButton.vue';
import Arrow from '@js/components/Icon/Arrow.vue';

export default defineComponent({
  components: { Arrow, OLButton },
  props: {
    role: {
      type: Object,
      default: () => {},
    },
  },

  setup(props) {
    const gotToJob = (url) => window.open(url);
    const findOutMore = (url) => window.open(route('jobs.show', props.role.id));
    return { gotToJob, findOutMore };
  },
});
</script>
