<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
  >
    <rect
      width="25"
      height="25"
      transform="translate(0 0.685547)"
      fill="#160535"
    />
    <path
      d="M21.0938 9.55121C22.0146 9.84751 22.6562 10.7264 22.6562 11.7358V16.1999C22.6562 17.3839 21.7735 18.3879 20.5935 18.4842C20.2404 18.513 19.8863 18.5382 19.5312 18.5598V21.7793L16.4062 18.6543C14.9961 18.6543 13.5996 18.5968 12.219 18.4841C11.9082 18.4587 11.6181 18.3704 11.3593 18.2325M21.0938 9.55121C20.936 9.50046 20.7701 9.4668 20.5978 9.4525C19.2158 9.3378 17.8178 9.2793 16.4062 9.2793C14.9946 9.2793 13.5967 9.33779 12.2147 9.4525C11.0365 9.55029 10.1562 10.5535 10.1562 11.7358V16.1998C10.1562 17.072 10.6353 17.8465 11.3593 18.2325M21.0938 9.55121V7.59941C21.0938 5.91043 19.894 4.44664 18.2191 4.2292C16.0915 3.953 13.9219 3.81055 11.719 3.81055C9.51597 3.81055 7.34617 3.95303 5.21837 4.22929C3.54347 4.44674 2.34375 5.91053 2.34375 7.59949V14.0841C2.34375 15.7731 3.54347 17.2369 5.21838 17.4543C5.81938 17.5324 6.42374 17.5997 7.03125 17.6562V22.5605L11.3593 18.2325"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
