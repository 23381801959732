<template>
  <div
    class="justify-center py-[144px] gap-x-12 text-white bg-ol-dark-ink-200 flex flex-col md:flex-row"
  >
    <div class="flex w-full md:w-[305px] lg:w-[358px]">
      <div
        class="bg-ol-secondary-20 w-full my-auto h-[285px] xs:h-[363px] sm:h-[680px] md:[w-246px] md:h-[258px] lg:h-[325px] lg:w-[305px] rounded-2xl flex justify-center"
      >
        <img class="md:w-[140px] h-auto mx-auto my-auto" :src="url" />
      </div>
    </div>
    <div class="md:w-1/2 flex flex-col my-auto">
      <h1 class="text-4xl mb-12 mt-12 md:mt-0">Proud to be a B Corp</h1>
      <p class="text-lg">
        We are very proud to announce we are officially B Corp accredited.
      </p>
      <br />
      <p class="text-lg">
        To gain B Corp certification, a company needs to meet high standards and
        prove it has a positive impact not just on its workers and customers,
        but also on the wider community and environment.
      </p>
      <br />
      <a
        target="_blank"
        class="text-lg underline"
        href="https://www.bcorporation.net/en-us/"
        >Learn more about what it means to be B Corp Certified</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'BCorp.vue',

  setup() {
    const url = new URL('/public/theme/img/B-Corp-logo.png', import.meta.url);
    return { url };
  },
};
</script>

<style scoped></style>
