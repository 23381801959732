<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <g clip-path="url(#clip0_6043_6436)">
      <rect
        width="20.8333"
        height="20.8333"
        transform="translate(0.166504 0.0602417)"
        fill="#160535"
      />
      <path
        d="M8.89063 4.03158C8.89063 3.2765 8.27852 2.66439 7.52345 2.66439C6.76837 2.66439 6.15626 3.2765 6.15626 4.03158L6.15625 6.63574M8.89063 4.03158L8.89064 2.72949C8.89064 1.97442 9.50275 1.3623 10.2578 1.3623C11.0129 1.3623 11.625 1.97442 11.625 2.72949L11.625 4.03158M8.89063 4.03158L8.95573 9.1748M11.625 9.82585V4.03158M11.625 4.03158C11.625 3.2765 12.2371 2.66439 12.9922 2.66439C13.7473 2.66439 14.3594 3.2765 14.3594 4.03158V13.0811M6.15625 6.63574C6.15625 5.88067 5.54414 5.26855 4.78906 5.26855C4.03399 5.26855 3.42188 5.88067 3.42188 6.63574V13.7321C3.42188 16.9681 6.04521 19.5915 9.28125 19.5915H11.0329C12.2415 19.5915 13.4007 19.1113 14.2554 18.2567L15.7589 16.7531C16.6136 15.8984 17.0937 14.7393 17.0937 13.5306L17.0964 11.7737C17.0977 11.6235 17.1546 11.478 17.268 11.3646C17.8019 10.8307 17.8019 9.96506 17.268 9.43114C16.7341 8.89722 15.8684 8.89722 15.3345 9.43114C14.6906 10.0751 14.3661 10.9206 14.362 11.7629M6.15625 6.63574V10.4769M11.599 14.2252C11.9374 13.8867 12.3225 13.6234 12.7335 13.4351C13.2483 13.1993 13.8038 13.0813 14.3594 13.0811M14.3611 13.0811H14.3594"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6043_6436">
        <rect
          width="20.8333"
          height="20.8333"
          fill="white"
          transform="translate(0.166504 0.0602417)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'HandRaised',
};
</script>
