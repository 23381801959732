<template>
  <div class="flex justify-center m-2 xs:mx-3 md:m-3 my-4">
    <p
      :class="`text-center text-sm p-2 ${bgColour} text-white rounded-2xl w-full max-w-[96rem]`"
    >
      <slot />
    </p>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'OLBanner',
  props: {
    colour: {
      type: String,
      default: 'primary',
    },
  },

  setup(props) {
    const bgColour = computed(() => {
      switch (props.colour) {
        case 'blue':
          return 'bg-gradient-l-blue-dark';
        case 'primary':
        default:
          return 'bg-gradient-to-r from-ol-primary-200 via-ol-primary-100 to-ol-primary-50';
      }
    });

    return { bgColour };
  },
};
</script>

<style scoped></style>
