<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20"
    viewBox="0 0 21 20"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2045 4.15347C17.534 4.4045 17.5976 4.87509 17.3466 5.20457L9.34657 15.7046C9.21541 15.8767 9.01627 15.9838 8.80033 15.9983C8.58439 16.0129 8.37271 15.9334 8.21967 15.7804L3.71967 11.2804C3.42678 10.9875 3.42678 10.5126 3.71967 10.2197C4.01256 9.92682 4.48744 9.92682 4.78033 10.2197L8.67351 14.1129L16.1534 4.29551C16.4045 3.96603 16.8751 3.90243 17.2045 4.15347Z"
    />
  </svg>
</template>
