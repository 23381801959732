<template>
  <section class="bg-ol-cream p-2 sm:p-3 !pb-0">
    <Container :is-grid="false" class="experts-card" data-testid="experts-card">
      <h3 class="experts-card__header" data-testid="experts-card-header">
        Our team of solicitors
      </h3>
      <div class="experts-card__content">
        <h4
          class="experts-card__subheader"
          data-testid="experts-card-subheader"
        >
          Our team of experts are here to help. We do the hard work for you so
          you can focus on what's important.
        </h4>
        <div
          class="experts-card__headshots"
          data-testid="experts-card-headshots"
        >
          <img :src="getHeadshot('laura')" alt="Photo of Laura" />
          <img :src="getHeadshot('june')" alt="Photo of June" />
          <img :src="getHeadshot('callum')" alt="Photo of Callum" />
        </div>
        <a href="javascript:Intercom('show');" class="w-full sm:w-fit">
          <CTA use-new class="experts-card__cta">Speak to our team</CTA>
        </a>
      </div>
    </Container>
  </section>
</template>

<script>
import CTA from '@components/Button/Cta.vue';
import Container from '@components/Core/Container.vue';

export default {
  components: {
    CTA,
    Container,
  },
  setup() {
    const getHeadshot = (name) => {
      return new URL(`/public/theme/img/headshots/${name}.jpg`, import.meta.url)
        .href;
    };
    return {
      getHeadshot,
    };
  },
};
</script>

<style lang="scss" scoped>
.experts-card {
  @apply w-full
    flex-col
    lg:flex-row
    justify-center
    !max-w-full
    bg-ol-plum
    !px-5 xs:!px-8 md:!px-24
    !py-16 md:!py-20 lg:!py-28
    rounded-2xl
    gap-y-5
    lg:gap-x-[min(10%,12rem)];
}

.experts-card__header {
  @apply text-ol-cream
    text-2xl xs:text-3xl sm:text-4xl
    2xl:ml-[10%]
    text-nowrap;
}

.experts-card__subheader {
  @apply text-ol-cream xs:text-xl font-light;
}

.experts-card__content {
  @apply flex flex-col gap-y-12 max-w-xl;
}

.experts-card__headshots {
  @apply flex
    h-16 md:h-20
    ml-4;

  > img {
    @apply -ml-4 aspect-1 max-h-full rounded-full h-auto border-2 border-white;
  }
}

.experts-card__cta {
  @apply border-2 border-white hover:border-ol-accent-1-100;
}
</style>
