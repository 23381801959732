<template>
  <section class="hero">
    <container class="overflow-hidden">
      <VerticalCarousel
        v-if="!hideCarousel"
        class="hidden lg:block"
        :class="heroOrder.image"
        :photos="photos"
      ></VerticalCarousel>

      <div class="hero__info" :class="heroOrder.text">
        <div
          class="flex flex-col items-start justify-center h-full content-between"
        >
          <div>
            <h1 class="hero__title" data-testid="brochure-hero-title">
              {{ title }}
            </h1>
            <h3
              class="text-white text-2xl 2xl:text-3xl"
              data-testid="brochure-hero-subtitle"
            >
              {{ subtitle }}
            </h3>
          </div>
          <div class="text-white flex flex-col py-9 xl:pb-16 xl:pt-14">
            <HeroFeatures :features="features"></HeroFeatures>
          </div>

          <ButtonRounded
            size="medium"
            variant="pink"
            :width="buttonWidth"
            data-testid="brochure-hero-button"
            @click.native="goToRoute(primaryButton.route)"
            class="flex justify-center gap-3 mx-auto sm:mx-0 mb-14"
          >
            <div class="my-auto">{{ primaryButton.text }}</div>

            <Arrow class="my-auto"></Arrow>
          </ButtonRounded>

          <TrustPilotStars class="mx-auto md:mx-0"></TrustPilotStars>
        </div>
      </div>
    </container>
  </section>
</template>

<script>
import Container from '@components/Core/Container.vue';
import MainNavCTA from '@components/MainNav/partials/MainNavCTA.vue';
import VerticalCarousel from '@components/Brochure/VerticalCarousel.vue';
import HeroFeatures from '@components/Brochure/HeroFeatures.vue';
import ButtonRounded from '@components/Button/ButtonRounded.vue';
import Arrow from '@components/Icon/Arrow.vue';
import TrustPilotStars from '@components/Brochure/TrustPilotStars.vue';
import { useScreenSize } from '@js/composables/useScreenSizeHelper';
import { computed } from 'vue';

export default {
  components: {
    TrustPilotStars,
    Arrow,
    ButtonRounded,
    HeroFeatures,
    VerticalCarousel,
    Container,
    MainNavCTA,
  },
  props: {
    primaryButton: { type: Object },
    secondaryButton: { type: Object },
    title: { type: String },
    photos: { type: Array, default: [] },
    subtitle: { type: String },
    features: { type: Array, default: [] },
    order: {
      type: String,
      default: 'normal',
    },
    hideCarousel: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const { screenWidth } = useScreenSize();
    const heroOrder = computed(() => {
      if (props.order === 'reverse') {
        return {
          image: 'hero__reverse__image',
          text: 'hero__reverse__text',
        };
      } else
        return {
          image: 'hero__normal__image',
          text: 'hero__normal__text',
        };
    });

    const buttonWidth = computed(() => {
      return screenWidth.value < 640 ? 'full' : 'auto';
    });

    const goToRoute = (route) => {
      window.location = route;
    };

    return { screenWidth, buttonWidth, goToRoute, heroOrder };
  },
};
</script>

<style scoped>
.hero {
  @apply py-12 lg:py-0 bg-ol-dark-ink-200;
}

.hero__info {
  @apply mb-6 lg:my-20 md:h-auto col-span-8 lg:col-span-5;
}

.hero__title {
  @apply text-white text-ol-heading-2 text-4xl xl:text-5xl mb-7;
}

.hero__reverse__image {
  @apply order-1 xl:col-start-2;
}

.hero__reverse__text {
  @apply order-2 xl:col-start-3;
}

.hero__normal__image {
  @apply xl:col-start-7 order-2;
}

.hero__normal__text {
  @apply order-1;
}
</style>
