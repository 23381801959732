<template>
  <div class="scenario-card" data-testid="scenario-card">
    <div class="flex flex-col">
      <div class="flex justify-between w-full">
        <p
          class="scenario-card__subheader"
          data-testid="scenario-card-subheader"
        >
          Scenario {{ scenarioNumber }}
        </p>
        <p class="scenario-card__value" data-testid="scenario-card-value">
          £{{ valueToLocaleString }}*
        </p>
      </div>
      <h1 class="scenario-card__header" data-testid="scenario-card-header">
        {{ header }}
      </h1>
    </div>
    <ul data-testid="scenario-card-bullets">
      <li v-for="(point, index) in bulletPoints" :key="index">• {{ point }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    scenarioNumber: {
      type: Number,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    bulletPoints: {
      type: Array,
      required: true,
    },
  },
  computed: {
    valueToLocaleString: (props) => {
      const value = props.value.toLocaleString();
      return /[0-9]{1,}\.[1-9]$/.test(value) ? `${value}0` : value;
    },
  },
};
</script>

<style lang="scss" scoped>
.scenario-card {
  @apply flex
    flex-col
    flex-grow
    flex-shrink-0
    gap-y-4
    p-10
    text-ol-dark-ink-200
    border-2
    rounded-2xl
    w-full
    lg:max-w-[calc(50%-1.5rem)];
}

.scenario-card__header {
  @apply font-bold
    text-lg lg:text-xl xl:text-2xl;
}

.scenario-card__subheader {
  @apply font-extralight
    uppercase
    text-dark-pewter-50
    md:text-lg;
}

.scenario-card__value {
  @apply border
    border-ol-dark-20
    py-1
    px-4
    rounded-full
    text-sm;
}
</style>
