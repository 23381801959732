<template>
  <div>
    <HeadingWithDescription
      :heading="heading"
      :paragraph="description"
      class="mb-10 lg:mb-20 bg-ol-accent-2-20"
    ></HeadingWithDescription>
    <div
      class="flex flex-col md:flex-row lg:max-w-screen-lg mx-auto justify-around gap-3 lg:gap-20"
    >
      <section
        v-for="coreValue in values"
        class="bg-transparent text-ol-dark-ink-200 font-bold flex flex-col mb-16 gap-3"
      >
        <component
          class="mx-auto w-auto h-40 lg:ml-0 md:mr-auto"
          :is="coreValue.icon"
        ></component>
        <div class="mt-auto">
          <h1 class="text-xl font-bold mb-3">
            {{ coreValue.title }}
          </h1>
          <p class="text-base font-normal lg:text-sm max-w-[655px] mx-auto">
            {{ coreValue.description }}
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import HeadingWithDescription from '@js/components/Brochure/HeadingWithDescription.vue';
import Lemonade from '@js/components/Icon/Lemonade.vue';
import LightBulbs from '@js/components/Icon/LightBulbs.vue';
import Kite from '@js/components/Icon/Kite.vue';
import { ref } from 'vue';

export default {
  components: { HeadingWithDescription, Lemonade, LightBulbs, Kite },

  setup() {
    const heading = ref('Our core values');
    const description =
      ref(`Each value guides our culture, aligns people, and focuses on products and customers.
      They influence choices in identity, actions, and hiring.
      They’re ingrained in our DNA as we evolve and grow.`);
    const values = ref([
      {
        title: 'Optimism',
        description:
          'Bringing death to life is about being a glass-half-fuller. Success is easy when you focus on the things that matter.',
        icon: 'Lemonade',
      },
      {
        title: 'Hard working',
        description:
          'There’s no such thing as great success without hard work. We’re curious - always learning, testing and improving.',
        icon: 'LightBulbs',
      },
      {
        title: 'Customer first',
        description:
          'We’re obsessed with meeting our customers needs and delivering the best experience and value for them.',
        icon: 'Kite',
      },
    ]);
    return { heading, description, values };
  },
};
</script>
