<template>
  <div class="flex flex-col items-center" :key="product.name">
    <component class="h-40" :is="product.svg"></component>
    <p class="mt-6 my-6 text-center text-xl w-36">
      {{ product.title }}
    </p>
  </div>
</template>
<script>
import PenPaper from '../Icon/PenPaper.vue';
import ArrowTarget from '../Icon/ArrowTarget.vue';
import TwoPages from '../Icon/TwoPages.vue';
import ClosedSafe from '../Icon/ClosedSafe.vue';

export default {
  components: {
    PenPaper,
    ArrowTarget,
    TwoPages,
    ClosedSafe,
  },
  props: { product: { type: Object } },
};
</script>
