<template>
  <div class="comparison-table">
    <div class="flex flex-col gap-y-4 h-full">
      <div>
        <div class="border-b-4">
          <h2
            class="comparison-table__header"
            :data-testid="dataTestid ? `${dataTestid}-header` : null"
          >
            <slot name="title">Heading</slot>
          </h2>
        </div>

        <h3
          class="text font-bold text-5xl py-12 text-center"
          :data-testid="dataTestid ? `${dataTestid}-price` : null"
        >
          <slot name="price">Price</slot>
        </h3>

        <ul class="comparison-table__list">
          <li
            v-for="(point, index) in points"
            :key="index"
            class="comparison-table__list__item"
          >
            <span>
              <checkmark></checkmark>
            </span>
            <span>{{ point }}</span>
          </li>
        </ul>
      </div>

      <div
        v-if="ctaLabel && ctaLink"
        class="flex flex-col mt-auto pt-4 lg:pt-8 px-4"
      >
        <a :href="route(ctaLink)">
          <ButtonRounded
            width="full"
            variant="sectionCta"
            :data-testid="
              dataTestid ? `${dataTestid}-same-as-mine-checkbox` : null
            "
          >
            {{ ctaLabel }}
          </ButtonRounded>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import OlButton from '@components/Form/Button.vue';
import ButtonRounded from '@components/Button/ButtonRounded.vue';
import Checkmark from '@components/Icon/Checkmark.vue';

export default {
  components: {
    Checkmark,
    ButtonRounded,
    OlButton,
  },

  props: {
    points: {
      type: Array,
      default: [''],
      required: true,
    },
    ctaLabel: {
      type: String,
      default: 'Label',
      required: false,
    },
    dataTestid: {
      type: String,
      default: null,
    },
    ctaLink: {
      type: String,
      default: '',
      required: false,
    },
  },
};
</script>

<style scoped>
.comparison-table {
  @apply flex flex-col lg:flex-1 text-ol-dark-ink-200 border-4 border-ol-dark-ink-200 w-full
  rounded-xl md:rounded-none md:first:border-r-0 first:rounded-tl-xl
  first:rounded-bl-xl last:rounded-tr-xl last:rounded-br-xl pb-16;
}

.comparison-table__header {
  @apply text-2xl lg:text-3xl  py-4 lg:py-8 text-center;
}

.comparison-table__list {
  @apply flex flex-col gap-y-4 w-9/10 lg:w-8/10 mx-auto;
}

.comparison-table__list__item {
  @apply font-medium text-lg flex items-center gap-x-4 leading-normal;
}
</style>
