<template>
  <div class="flex flex-col">
    <HeadingWithDescription
      class="px-5 lg:px-32 py-20 bg-ol-secondary-20 lg:py-28 xl:py-36"
      :heading="pageTop.heading"
      :paragraph="pageTop.paragraph"
    >
      <template #button>
        <a href="#openRoles" class="md:mx-auto">
          <OLButton
            :width="null"
            variant="primary"
            colour="special"
            size="small"
            class="w-full md:w-auto mt-8 md:mt-14 flex gap-x-2 justify-center"
          >
            <span class="my-auto font-medium">View open roles</span>
            <ArrowDown></ArrowDown>
          </OLButton>
        </a>
      </template>
    </HeadingWithDescription>
    <SamsStory class="px-5 md:px-10 lg:px-20 max-w-[1369px]">
      <template #title>{{ ourJob.heading }}</template>
      <template #body>
        <div class="flex flex-col gap-y-4">
          <p>
            {{ ourJob.paragraph1 }}
          </p>
          <p>
            {{ ourJob.paragraph2 }}
          </p>
        </div>
      </template>
    </SamsStory>
    <div class="w-full bg-ol-secondary-20">
      <FromOurTeam
        class="px-5 max-w-[1200px] py-20 lg:py-28 xl:py-36 mx-auto bg-ol-secondary-20"
        :fromOurTeam="fromOurTeam"
      ></FromOurTeam>
    </div>
    <PerksSection class="px-5 lg:px-32 py-20 lg:py-28 xl:py-36"></PerksSection>
    <CoreValues
      class="px-5 lg:px-32 py-20 lg:py-28 xl:py-36 bg-ol-accent-2-20"
    ></CoreValues>
    <div class="w-full bg-ol-dark-ink-200">
      <BCorp
        class="px-5 py-20 lg:py-28 xl:py-36 max-w-[1536px] mx-auto"
      ></BCorp>
    </div>
    <ActiveJobs
      class="px-5 xl:px-32 py-20 xl:py-28 2xl:py-36 max-w-[1536px] mx-auto"
      id="openRoles"
      :jobs="jobs"
    ></ActiveJobs>
    <SectionCta></SectionCta>
  </div>
</template>

<script>
import { ref } from 'vue';

import HeadingWithDescription from '@js/components/Brochure/HeadingWithDescription.vue';
import ButtonRounded from '@js/components/Button/ButtonRounded.vue';
import SamsStory from '@js/components/SamsStory.vue';
import BCorp from '@js/components/Brochure/BCorp.vue';
import PerksSection from '@js/components/Brochure/careers/PerksSection.vue';
import ActiveJobs from '@js/components/Brochure/careers/ActiveJobs.vue';
import FromOurTeam from '@js/components/Brochure/careers/FromOurTeam.vue';
import SectionCta from '@js/components/Footer/SectionCta.vue';
import CoreValues from '@js/components/Brochure/CoreValues.vue';
import ArrowDown from '@js/components/Icon/ArrowDown.vue';
import OLButton from '@js/components/Button/OLButton.vue';

export default {
  name: 'Careers',
  components: {
    ArrowDown,
    CoreValues,
    FromOurTeam,
    ActiveJobs,
    PerksSection,
    BCorp,
    HeadingWithDescription,
    ButtonRounded,
    SamsStory,
    SectionCta,
    OLButton,
  },

  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
  },

  setup(props) {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
          behavior: 'smooth',
        });
      });
    });

    const pageTop = ref({
      heading: 'Work at Octopus Legacy',
      paragraph: `We’re tackling some of the biggest taboos surrounding death.
        Together, we can help to make some of the toughest times in people’s lives easier.`,
    });

    const fromOurTeam = ref({
      heading: 'From our team',
      paragraph: `From weekly all-hands meetings to quarterly planning sessions, you can share your ideas,
      collaborate with other teams and help us change the way the world deals with death.`,
      image1: new URL('/public/theme/ol/careers1.jpg', import.meta.url),
      video: new URL('/public/theme/ol/sports-day.mp4', import.meta.url),
      image2: new URL('/public/theme/ol/careers3.jpg', import.meta.url),
      testimonials: [
        {
          title: 'The company culture is fantastic',
          review: `The fast-paced, high-growth environment keeps me constantly challenged and engaged.
          I love the collaborative spirit among team members which makes every project a rewarding experience.`,
          name: 'Imogen',
          jobTitle: 'Senior Product Manager',
          image: new URL(
            '/public/theme/img/headshots/imogen.jpg',
            import.meta.url
          ),
        },
        {
          title: 'I get to help people. Every day!',
          review: `When I talk about Wills, Lasting Powers of Attorney, Inheritance Tax or anything else,
          I help people to focus on what really matters: their loved ones and their legacy. You don't get that everywhere.`,
          name: 'Rashawn',
          jobTitle: 'Estate Planner',
          image: new URL(
            '/public/theme/img/headshots/rashawn.jpg',
            import.meta.url
          ),
        },
        {
          title:
            'We are creating a leading probate service, designed to be more compassionate and accessible for families.',
          review: `Octopus Legacy has a unique opportunity to initiate and nurture meaningful conversations,
          breaking down barriers surrounding death and complex processes,
          to drive change for the benefit of grieving families during a difficult time.`,
          name: 'June',
          jobTitle: 'Senior Probate Solicitor',
          image: new URL(
            '/public/theme/img/headshots/june.jpg',
            import.meta.url
          ),
        },
      ],
    });

    const ourJob = ref({
      heading: 'Our job is to bring death to life.',
      paragraph1:
        'Born after Sam’s mum died in a car accident, we know the difference a good plan makes, and what it’s like when there isn’t one.',
      paragraph2:
        'We see a world where you work out your personal meaning of legacy by talking about death with people you love. We’re here to make that happen.',
    });

    return { pageTop, ourJob, fromOurTeam };
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}
</style>
