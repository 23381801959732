<template>
  <svg
    width="54"
    height="54"
    viewBox="0 0 54 54"
    fill="none"
    class="w-8 h-8 lg:w-auto lg:h-auto"
  >
    <rect
      x="0.791992"
      width="53.208"
      height="53.244"
      rx="26.604"
      fill="#160535"
    />
    <path
      d="M18.792 25.128H36V28.152H18.792V25.128ZM28.908 18V35.244H25.884V18H28.908Z"
      fill="white"
    />
  </svg>
</template>
