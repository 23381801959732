<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <g clip-path="url(#clip0_6043_6428)">
      <rect
        width="20.8333"
        height="20.8333"
        transform="translate(0.166504 0.0602417)"
        fill="#160535"
      />
      <path
        d="M13.1873 16.664C13.9104 16.8745 14.675 16.9873 15.4659 16.9873C16.7496 16.9873 17.9638 16.6901 19.0436 16.1608C19.0456 16.1111 19.0467 16.061 19.0467 16.0108C19.0467 14.0332 17.4435 12.4301 15.4659 12.4301C14.235 12.4301 13.1492 13.0512 12.5047 13.9971M13.1873 16.664V16.6618C13.1873 15.6958 12.9398 14.7875 12.5047 13.9971M13.1873 16.664C13.1873 16.695 13.1871 16.7259 13.1865 16.7567C11.5705 17.7298 9.67733 18.2894 7.65348 18.2894C5.62963 18.2894 3.7365 17.7298 2.12043 16.7567C2.1199 16.7252 2.11963 16.6935 2.11963 16.6618C2.11963 13.6056 4.59722 11.128 7.65348 11.128C9.74369 11.128 11.5632 12.2868 12.5047 13.9971M10.5832 5.59412C10.5832 7.21214 9.2715 8.5238 7.65348 8.5238C6.03546 8.5238 4.7238 7.21214 4.7238 5.59412C4.7238 3.97609 6.03546 2.66443 7.65348 2.66443C9.2715 2.66443 10.5832 3.97609 10.5832 5.59412ZM17.7446 7.54724C17.7446 8.8057 16.7244 9.82589 15.466 9.82589C14.2075 9.82589 13.1873 8.8057 13.1873 7.54724C13.1873 6.28878 14.2075 5.2686 15.466 5.2686C16.7244 5.2686 17.7446 6.28878 17.7446 7.54724Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6043_6428">
        <rect
          width="20.8333"
          height="20.8333"
          fill="white"
          transform="translate(0.166504 0.0602417)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Users',
};
</script>
