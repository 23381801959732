<template>
  <div class="carousel">
    <div class="carousel__images">
      <image-lazy-load
        :image-class="imageClass(index)"
        v-for="(photo, index) in photos"
        :key="photo.name"
        :alt="photo.name"
        :src="photo.src"
      ></image-lazy-load>
    </div>
  </div>
</template>
<script>
import ImageLazyLoad from '@components/ImageLazyLoad.vue';

export default {
  components: { ImageLazyLoad },
  props: { photos: { Array } },
  setup() {
    const imageClass = (i) => {
      if (i === 0) {
        return 'rounded-ol-small lg:rounded-ol-large block object-cover aspect-[612/650] my-3 blur';
      } else if (i === 1) {
        return 'mt-6 rounded-ol-small lg:rounded-ol-large block object-cover aspect-[612/650] my-3 object-left';
      }
      return 'rounded-ol-small lg:rounded-ol-large block object-cover aspect-[612/650] my-3 blur';
    };

    return { imageClass };
  },
};
</script>

<style scoped>
.carousel {
  @apply hidden col-span-6 lg:flex xl:col-start-2 xl:col-span-5 flex-col justify-center relative;
}

.carousel__images {
  @apply absolute top-1/2 -translate-y-1/2 ml-5;
}
</style>
