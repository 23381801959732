<template>
  <div class="p-7 xl:p-10 flex flex-col bg-white rounded-ol-card">
    <open-quote class="mb-2"></open-quote>
    <h1 class="text-2xl text-ol-dark-ink-200 font-bold mb-5">
      {{ testimonial.title }}
    </h1>
    <p class="mb-20 font-medium text-ol-ashes">{{ testimonial.review }}</p>
    <EmployeeCard
      :name="testimonial.name"
      :image="testimonial.image"
      :job-title="testimonial.jobTitle"
    ></EmployeeCard>
  </div>
</template>

<script>
import OpenQuote from '@components/Icon/OpenQuote.vue';
import AvatarIcon from '@js/components/AvatarIcon.vue';
import EmployeeCard from '@js/components/Brochure/EmployeeCard.vue';

export default {
  components: { EmployeeCard, AvatarIcon, OpenQuote },
  props: {
    testimonial: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
