<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <rect
      width="20.8333"
      height="20.8333"
      transform="translate(0.166504 0.0601807)"
      fill="#160535"
    />
    <path
      d="M14.6736 3.81873C15.1836 4.13265 15.6653 4.51058 16.1073 4.95253C16.5492 5.39449 16.9272 5.87622 17.2411 6.38621M14.6736 3.81873L11.6799 7.41117M14.6736 3.81873C12.173 2.2795 8.99304 2.2795 6.49241 3.81873M17.2411 6.38621L13.6486 9.3799M17.2411 6.38621C18.7803 8.88684 18.7803 12.0668 17.2411 14.5674M13.6486 9.3799C13.4916 8.94004 13.237 8.52722 12.8848 8.17503C12.5326 7.82283 12.1198 7.56821 11.6799 7.41117M13.6486 9.3799C13.9014 10.0878 13.9014 10.8658 13.6486 11.5737M11.6799 7.41117C10.972 7.15841 10.194 7.15841 9.4861 7.41117M13.6486 11.5737C13.4916 12.0136 13.237 12.4264 12.8848 12.7786C12.5326 13.1308 12.1198 13.3854 11.6799 13.5424M13.6486 11.5737L17.2411 14.5674M17.2411 14.5674C16.9272 15.0774 16.5492 15.5591 16.1073 16.0011C15.6653 16.443 15.1836 16.821 14.6736 17.1349M14.6736 17.1349L11.6799 13.5424M14.6736 17.1349C12.173 18.6741 8.99305 18.6741 6.49241 17.1349M11.6799 13.5424C10.972 13.7952 10.194 13.7952 9.4861 13.5424M9.4861 13.5424C9.04625 13.3854 8.63342 13.1308 8.28123 12.7786C7.92903 12.4264 7.67441 12.0136 7.51737 11.5737M9.4861 13.5424L6.49241 17.1349M6.49241 17.1349C5.98242 16.821 5.50069 16.443 5.05874 16.0011C4.61678 15.5591 4.23885 15.0774 3.92493 14.5674M3.92493 14.5674L7.51737 11.5737M3.92493 14.5674C2.3857 12.0668 2.3857 8.88684 3.92493 6.38621M7.51737 11.5737C7.26461 10.8658 7.26461 10.0878 7.51737 9.3799M7.51737 9.3799C7.67441 8.94004 7.92903 8.52722 8.28123 8.17503C8.63342 7.82283 9.04625 7.56821 9.4861 7.41117M7.51737 9.3799L3.92493 6.38621M9.4861 7.41117L6.49241 3.81873M6.49241 3.81873C5.98242 4.13265 5.50069 4.51058 5.05874 4.95253C4.61678 5.39449 4.23885 5.87622 3.92493 6.38621"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LifeBuoy',
};
</script>
