<template>
  <section id="scenario-section" data-testid="scenario-section">
    <div class="flex flex-col items-center max-w-6xl gap-y-7 md:gap-y-10">
      <div class="flex flex-col gap-y-7">
        <h3
          class="scenario-section__header"
          data-testid="scenario-section-header"
        >
          {{ header }}
        </h3>
        <h4
          class="scenario-section__description"
          data-testid="scenario-section-description"
        >
          {{ description }}
        </h4>
      </div>
      <div
        class="scenario-section__container"
        data-testid="scenario-section-container"
      >
        <ScenarioCard
          v-for="(scenario, index) in scenarios"
          :key="index"
          :scenario-number="index + 1"
          :header="scenario.header"
          :value="scenario.value"
          :bullet-points="scenario.bullets"
        />
      </div>
      <p
        class="scenario-section__fineprint"
        data-testid="scenario-section-fineprint"
      >
        *VAT (at 20%) is included in the price. There is a probate registry fee
        of £300, and copies of the grant of probate cost £1.50 each. There may
        also be additional costs or services depending on the estate which will
        be confirmed where necessary.
      </p>
    </div>
  </section>
</template>

<script>
import ScenarioCard from '@components/Brochure/ScenarioCard.vue';

export default {
  components: {
    ScenarioCard,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    scenarios: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#scenario-section {
  @apply flex justify-center bg-ol-cream w-full px-7 md:px-10 py-20 md:py-36;
}

.scenario-section__header {
  @apply text-ol-plum text-4xl lg:text-center;
}

.scenario-section__description {
  @apply text-ol-plum text-lg lg:text-xl lg:text-center;
}

.scenario-section__container {
  @apply flex justify-center flex-wrap gap-x-10 xl:gap-x-12 gap-y-5 md:gap-y-6;
}

.scenario-section__fineprint {
  @apply text-ol-dark-ink-200 text-[0.625rem] sm:text-xs md:text-center max-w-192;
}
</style>
