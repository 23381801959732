<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <path
      d="M2.11963 16.3362C6.86546 16.3362 11.463 16.971 15.8321 18.1602C16.4632 18.332 17.0936 17.8628 17.0936 17.2087V16.3362M3.42171 3.96643V4.61747C3.42171 4.97703 3.13023 5.26851 2.77067 5.26851H2.11963M2.11963 5.26851V4.94299C2.11963 4.40365 2.55685 3.96643 3.09619 3.96643H17.7446M2.11963 5.26851V13.081M17.7446 3.96643V4.61747C17.7446 4.97703 18.0361 5.26851 18.3957 5.26851H19.0467M17.7446 3.96643H18.0702C18.6095 3.96643 19.0467 4.40365 19.0467 4.94299V13.4065C19.0467 13.9459 18.6095 14.3831 18.0702 14.3831H17.7446M19.0467 13.081H18.3957C18.0361 13.081 17.7446 13.3725 17.7446 13.7321V14.3831M17.7446 14.3831H3.42171M3.42171 14.3831H3.09619C2.55685 14.3831 2.11963 13.9459 2.11963 13.4065V13.081M3.42171 14.3831V13.7321C3.42171 13.3725 3.13023 13.081 2.77067 13.081H2.11963M13.1873 9.17476C13.1873 10.613 12.0214 11.7789 10.5832 11.7789C9.14493 11.7789 7.979 10.613 7.979 9.17476C7.979 7.73652 9.14493 6.5706 10.5832 6.5706C12.0214 6.5706 13.1873 7.73652 13.1873 9.17476ZM15.7915 9.17476H15.798V9.18128H15.7915V9.17476ZM5.37484 9.17476H5.38135V9.18128H5.37484V9.17476Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'BankNotes',
};
</script>
