<template>
  <div>
    <h1
      class="lg:text-center text-ol-dark-ink-200 font-bold text-3xl mb-12 md:text-4xl sm:mb-14 2xl:text-5xl"
    >
      Perks & Benefits
    </h1>
    <div
      class="flex gap-7 lg:gap-10 flex-wrap justify-center max-w-[1273px] mx-auto"
    >
      <BoxWithIcon
        v-for="perk in perks"
        :key="perk.title"
        :id="perk.title"
        :title="perk.title"
        :icon="perk.icon"
        :paragraph="perk.paragraph"
        :iconClass="perk.iconClass"
      ></BoxWithIcon>
    </div>
  </div>
</template>
<script>
import BoxWithIcon from '@js/components/Brochure/BoxWithIcon.vue';
import { ref } from 'vue';

export default {
  name: 'PerksSection',
  components: { BoxWithIcon },
  setup() {
    const perks = ref([
      {
        title: 'Flexible Working',
        icon: 'RocketLaunch',
        paragraph:
          'Hybrid working; 3 days in the office (Holborn). Flexible holiday policy and work from anywhere four weeks a year.',
      },
      {
        title: 'Share Incentive Scheme (SIP)',
        icon: 'BankNotes',
        paragraph:
          'Join the Octopus Share Incentive Plan and invest in the company. ',
      },
      {
        title: 'Health and Life Insurance',
        icon: 'LifeBuoy',
        paragraph:
          'We offer Vitality Health & Life Insurance.  In addition, you get access to a free will and LPA.',
      },
      {
        title: 'Cycle to work scheme',
        icon: 'Bolt',
        paragraph:
          'Enjoy our cycle to work scheme and access to the Electric Vehicle Sacrifice Scheme.',
      },
      {
        title: 'Mat & Pat Leave',
        icon: 'Users',
        paragraph:
          'We offer enhanced parental leave, providing extended time off to support new parents.',
      },
      {
        title: 'Charity matching',
        icon: 'HandRaised',
        paragraph:
          'We match charitable donations up to £500 and support volunteer days throughout the year.',
      },
      {
        title: 'Octopus Springboard',
        icon: 'Trophy',
        paragraph:
          'Become a fully-fledged entrepreneur with cash funding and time off to start your own business.',
      },
      {
        title: 'Birthdays off',
        icon: 'Cake',
        paragraph:
          'For a business that deals with death, we’re adamant about celebrating life.',
      },
      {
        title: 'Events and socials',
        icon: 'Ticket',
        paragraph:
          'Company off-site, legendary team socials, free breakfast, drinks and snacks.',
      },
      {
        title: 'Dog-friendly office',
        icon: 'Sparkles',
        iconClass: 'fill-ol-dark-ink-200 stroke-white',
        paragraph:
          'We are dog-friendly! Feel free to bring in your (well-behaved) floofer.',
      },
    ]);
    return { perks };
  },
};
</script>

<style scoped></style>
