<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <rect
      width="20.8333"
      height="20.8333"
      transform="translate(0.166504 0.0602226)"
      fill="#160535"
    />
    <path
      d="M10.583 7.22168V5.9196M10.583 7.22168C9.40666 7.22168 8.24169 7.27043 7.09003 7.36602C6.10817 7.44751 5.37467 8.28352 5.37467 9.26875V11.4503M10.583 7.22168C11.7594 7.22168 12.9243 7.27043 14.076 7.36602C15.0578 7.44751 15.7913 8.28352 15.7913 9.26875V11.4503M13.1872 7.22168V5.9196M7.97884 7.22168V5.9196M18.3955 14.3831L17.0934 15.0342C16.2737 15.444 15.3089 15.444 14.4893 15.0342C13.6696 14.6243 12.7048 14.6243 11.8851 15.0342C11.0654 15.444 10.1006 15.444 9.28092 15.0342C8.46124 14.6243 7.49644 14.6243 6.67676 15.0342C5.85708 15.444 4.89227 15.444 4.07259 15.0342L2.77051 14.3831M15.7913 11.4503C14.0851 11.2375 12.3469 11.1279 10.583 11.1279C8.81915 11.1279 7.08089 11.2375 5.37467 11.4503M15.7913 11.4503C16.1291 11.4924 16.4657 11.5386 16.8009 11.5887C17.7289 11.7276 18.3955 12.5383 18.3955 13.4766V17.9639C18.3955 18.5032 17.9583 18.9404 17.4189 18.9404H3.74707C3.20773 18.9404 2.77051 18.5032 2.77051 17.9639V13.4766C2.77051 12.5383 3.43711 11.7276 4.3651 11.5887C4.70033 11.5386 5.03688 11.4924 5.37467 11.4503M10.8132 2.75973C10.9404 2.88686 10.9404 3.09297 10.8132 3.22009C10.6861 3.34721 10.48 3.34721 10.3529 3.22009C10.2258 3.09297 10.2258 2.88686 10.3529 2.75973C10.4185 2.69415 10.5831 2.52955 10.5831 2.52955C10.5831 2.52955 10.7517 2.69819 10.8132 2.75973ZM8.20907 2.75973C8.33619 2.88686 8.33619 3.09297 8.20907 3.22009C8.08194 3.34721 7.87584 3.34721 7.74871 3.22009C7.62159 3.09297 7.62159 2.88686 7.74871 2.75973C7.81429 2.69415 7.97889 2.52955 7.97889 2.52955C7.97889 2.52955 8.14752 2.69819 8.20907 2.75973ZM13.4174 2.75973C13.5445 2.88686 13.5445 3.09297 13.4174 3.22009C13.2903 3.34721 13.0842 3.34721 12.957 3.22009C12.8299 3.09297 12.8299 2.88686 12.957 2.75973C13.0226 2.69415 13.1872 2.52955 13.1872 2.52955C13.1872 2.52955 13.3559 2.69819 13.4174 2.75973Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Cake',
};
</script>
