<template>
  <div class="rounded-xl h-full">
    <div class="flex flex-col justify-between h-full">
      <div class="px-5 pt-8 pb-6 flex flex-col justify-between h-full gap-6">
        <p
          class="text-ga-testimonial lg:text-ga-body-small leading-8 lg:leading-10 font-medium"
          v-text="displayReview"
        />
        <div class="flex gap-3">
          <div class="w-1/2 lg:w-2/3">
            <span class="text-black font-medium text-xl" v-text="displayName" />
          </div>
        </div>
      </div>

      <div
        class="flex items-center justify-between px-5 py-6 border-t border-gray-50"
        v-if="trustpilot"
      >
        <div class="-ml-4">
          <!-- TrustBox widget - Micro Star -->
          <div
            class="trustpilot-widget"
            data-locale="en-GB"
            data-template-id="5419b732fbfb950b10de65e5"
            data-businessunit-id="657080ebfa8c7e8d395f33bd"
            data-style-height="18px"
            data-style-width="100%"
            data-theme="light"
          >
            <a
              href="https://uk.trustpilot.com/review/octopuslegacy.com"
              target="_blank"
              rel="noopener"
            >
            </a>
          </div>
          <!-- End TrustBox widget -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
      default: 'Name',
    },
    review: {
      type: String,
      required: true,
      default: 'Review goes here...',
    },
    trustpilot: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displayReview() {
      return `“${this.review}”`;
    },
    displayName() {
      return `– ${this.name}`;
    },
    displayRating() {
      return `Rated ${this.rating}/5`;
    },
  },
};
</script>
