<template>
  <section class="fwig-hero" data-testid="fwig-hero">
    <div>
      <div class="fwig-hero__container">
        <div>
          <h1 class="fwig-hero__header" data-testid="fwig-hero-header">
            Your legacy, gift wrapped for the people you love
          </h1>
          <p class="fwig-hero__subheader" data-testid="fwig-hero-subheader">
            Gather the essentials—your will, accounts, and policies—alongside
            the treasures that truly matter: your stories, letters, photos, and
            wisdom, all in one thoughtful box.
          </p>
        </div>
        <a
          :href="route('fwig.product')"
          class="w-full md:w-fit"
          data-testid="fwig-hero-cta"
        >
          <CTA class="fwig-hero__cta" use-new>Buy now</CTA>
        </a>
        <a
          class="trustpilot-widget"
          data-locale="en-GB"
          data-template-id="5419b732fbfb950b10de65e5"
          data-businessunit-id="657080ebfa8c7e8d395f33bd"
          data-style-height="20px"
          data-style-width="280px"
          data-font-family="Montserrat"
          href="https://uk.trustpilot.com/review/octopuslegacy.com"
          target="_blank"
          rel="noopener"
          data-testid="fwig-hero-trustpilot"
        />
      </div>
      <div class="fwig-hero__image" data-testid="fwig-hero-image">
        <img :src="background" alt="For when I go" />
      </div>
    </div>
  </section>
</template>

<script setup>
import CTA from '@components/Button/Cta.vue';

const background = new URL(
  '/public/theme/img/fwig/hero-image.avif',
  import.meta.url
);
</script>

<style lang="scss" scoped>
.fwig-hero {
  @apply flex
  items-center justify-center
  bg-ol-cream
  m-2 xs:mx-3 md:m-3;

  > div {
    @apply flex
    flex-col lg:flex-row
    gap-y-4 lg:gap-y-0
    lg:gap-x-4 xl:gap-x-16 2xl:gap-x-28
    w-full
    max-w-[96rem]
    md:py-4 lg:py-0;
  }
}

.trustpilot-widget {
  @apply w-fit;
}

.fwig-hero__container {
  @apply flex
  flex-col
  gap-y-8 md:gap-y-12
  bg-ol-cream
  py-4 sm:py-8 lg:py-12 xl:py-16
  xl:pl-8 2xl:pl-16
  lg:w-4/5
  mx-2 md:mx-4 lg:mx-0;
}

.fwig-hero__image {
  @apply relative
  aspect-1 lg:aspect-[unset]
  rounded-3xl
  bg-clip-content
  overflow-hidden
  md:mx-4 lg:mx-0
  lg:w-full;

  > img {
    @apply absolute
    top-1/2
    left-1/2
    min-h-full
    min-w-[101%]
    object-cover;
    transform: translate(-50.05%, -50%);
  }
}

.fwig-hero__header {
  @apply text-ol-dark-ink-200
  text-4xl xs:text-5xl md:text-6xl lg:text-5xl 2xl:text-6xl
  font-bold
  mb-7
  md:max-w-[28rem];
}

.fwig-hero__subheader {
  @apply text-ol-ashes
  text-xl md:text-2xl
  md:max-w-[34rem];
}

.fwig-hero__cta {
  @apply px-12 gap-4;
}
</style>
