<template>
  <in-view :disable-effect="true" :threshold="1" @visible="handleVisible">
    <svg
      width="190"
      height="190"
      viewBox="0 0 36 36"
      class="circular-chart purple"
    >
      <path
        class="circle-bg"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        class="circle transition-fill"
        :stroke-dasharray="strokeDashArray"
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <text x="18" y="20.35" class="percentage">
        {{ displayPercentage }}
      </text>
    </svg>
  </in-view>
</template>

<script>
import InView from '../../modules/InView.vue';

export default {
  name: 'Percentage',

  components: {
    InView,
  },

  props: {
    number: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      visible: false,
      strokeDashArray: '0, 100',
    };
  },

  computed: {
    displayPercentage() {
      return `${this.number}%`;
    },
  },

  methods: {
    handleVisible({ status }) {
      this.visible = status;
    },
  },

  watch: {
    visible: {
      handler(newValue) {
        if (newValue) {
          this.strokeDashArray = `${this.number}, 100`;
        }
      },
    },
  },
};
</script>

<style scoped>
.circular-chart {
  display: block;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #f2f2f2;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
}

.circular-chart.purple .circle {
  stroke: #8457e8;
}

.percentage {
  @apply fill-ol-purple;
  font-size: 0.55em;
  text-anchor: middle;
}

.transition-fill {
  transition-duration: 1500ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
</style>
