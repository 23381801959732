<template>
  <div
    class="bg-white rounded-[10px] py-8 px-6 shadow-lg rounded-lg group hover:shadow-xl"
  >
    <div class="flex flex-col gap-y-6">
      <h3 class="text-ol-heading-3 items-center text-ol-purple">
        <slot name="title"></slot>
      </h3>
      <div class="text-ol-heading-4">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>
