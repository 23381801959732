<template>
  <div>
    <HeadingWithDescription
      class="px-5 md:px-32 pb-10 lg:pb-14 bg-ol-secondary-20"
      :heading="fromOurTeam.heading"
      :paragraph="fromOurTeam.paragraph"
    >
    </HeadingWithDescription>
    <div class="row">
      <div class="column">
        <img class="my-4 rounded-ol-card" :src="fromOurTeam.image1" />

        <Testimonial
          class="flex-grow my-4"
          :testimonial="fromOurTeam.testimonials[0]"
        ></Testimonial>

        <img class="md:my-4 rounded-ol-card" :src="fromOurTeam.image2" />
      </div>
      <div class="column">
        <Testimonial
          class="flex-grow my-4"
          :testimonial="fromOurTeam.testimonials[1]"
        ></Testimonial>

        <video
          id="vid"
          autoplay
          loop
          muted
          class="my-4 rounded-ol-card"
          :src="fromOurTeam.video"
        />
        <Testimonial
          class="flex-grow my-4"
          :testimonial="fromOurTeam.testimonials[2]"
        ></Testimonial>
      </div>
    </div>
  </div>
</template>
<script>
import HeadingWithDescription from '@components/Brochure/HeadingWithDescription.vue';
import Testimonial from '@js/components/Brochure/careers/Testimonial.vue';

export default {
  components: { Testimonial, HeadingWithDescription },
  props: {
    fromOurTeam: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style>
.row {
  @apply flex flex-wrap;
}

.column {
  @apply w-full md:min-h-[1250px] md:flex-col md:flex md:justify-between md:w-1/2 md:px-4;
}
</style>
