<template>
  <SectionHeroImage
    image="theme/img/probate/ProbateHeroBackground.avif"
    title="Your guide through probate"
    sub-title="We're here to lighten your load so you can focus on what really matters."
    use-new
    data-testid="hero-section"
    border-css="bg-gradient-to-br from-ol-blue-100 to-ol-pink-20"
  >
    <template #body>
      <div
        class="hero-section__checkmarks"
        data-testid="hero-section-checkmarks"
      >
        <div
          v-for="feature in features"
          :key="feature"
          class="hero-section__checkmarks__item"
        >
          <Checkmark class="fill-ol-primary-200" />
          <h4>{{ feature }}</h4>
        </div>
      </div>
      <a
        :href="
          route('probate.form', {
            typeform: typeform,
            utmParams: utmParams,
          })
        "
      >
        <CTA use-new class="hero-section__cta" data-testid="hero-section-cta">
          Get Started
        </CTA>
      </a>
    </template>
  </SectionHeroImage>
</template>

<script>
import Checkmark from '@components/Icon/CheckmarkStandalone.vue';
import SectionHeroImage from '@components/SectionHeroImage.vue';
import CTA from '@components/Button/Cta.vue';

export default {
  components: {
    Checkmark,
    CTA,
    SectionHeroImage,
  },
  props: {
    typeform: {
      type: String,
      required: true,
    },
    utmParams: {
      type: String,
      required: true,
    },
  },
  setup() {
    const features = [
      'Tailored quotes & services',
      'Expert support',
      'Costs quoted and fixed ahead of time',
    ];
    return {
      features,
    };
  },
};
</script>

<style lang="scss" scoped>
.hero-section {
  @apply w-full;
}

.hero-section__container {
  @apply w-full
    rounded-3xl
    bg-gradient-to-br from-ol-blue-100 to-ol-pink-20
    p-0.5;
}

.hero-section__image {
  // 1.5rem - 0.125rem (rounded-3xl - p-0.5) to get inner border-radius
  @apply w-full
    rounded-[1.375rem]
    bg-clip-content;
}

.hero-section__checkmarks {
  @apply flex flex-col sm:flex-row
    flex-wrap
    justify-center
    gap-y-[0.625rem] sm:gap-y-1
    gap-x-5 lg:gap-x-7
    mt-8 xs:mt-10 sm:mt-8 md:mt-6 lg:mt-8
    mb-10 xs:mb-[3.38rem] sm:mb-11 md:mb-8;
}
.hero-section__checkmarks__item {
  @apply flex
  items-center
  gap-2;
}

.hero-section__cta {
  @apply w-full sm:w-fit;
}
</style>
