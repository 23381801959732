<template>
  <section class="fwig-box-story" data-testid="fwig-box-story">
    <div>
      <div class="fwig-box-story__image" data-testid="fwig-box-story-image">
        <div>
          <img :src="image" alt="Eliza and her grandad" />
        </div>
        <p>Founder and CEO, Sam with his Mum</p>
      </div>
      <div class="fwig-box-story__container">
        <h3 class="fwig-box-story__header" data-testid="fwig-box-story-header">
          The story behind the box
        </h3>
        <p class="fwig-box-story__text" data-testid="fwig-box-story-text">
          My mum died suddenly in 2016. Since then I've been working to make the
          experience of losing someone easier for those of us left behind.<br /><br />
          Part of this is helping people to put practical plans in place that
          save the people we leave behind hours of work and stress at an already
          overwhelming time.<br /><br />
          But it shouldn't stop there. The things I value most from Mum aren't
          the practical plans she shared. It's the book of her recipes she gave
          me one Christmas, the recording of her voice that I've played more
          times than any song on spotify, and her letters to me.<br /><br />
          This box is your chance to give the gifts that really matter to the
          people you leave behind.
        </p>
        <a
          :href="route('fwig.product')"
          class="w-full md:w-fit"
          data-testid="fwig-box-story-cta"
        >
          <CTA use-new class="fwig-box-story__cta">Buy now</CTA>
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
import CTA from '@components/Button/Cta.vue';

const image = new URL(
  '/public/theme/img/fwig/sam-and-mum-image.avif',
  import.meta.url
);
</script>

<style lang="scss" scoped>
.fwig-box-story {
  @apply flex
    justify-center
    items-center
    rounded-3xl
    m-2 xs:mx-3 md:m-3
    bg-ol-dark-ink-200
    text-white;

  > div {
    @apply flex
      flex-col lg:flex-row
      gap-y-16 lg:gap-y-0 lg:gap-x-16 xl:gap-x-30
      px-6 md:px-8 lg:px-16 xl:px-32
      py-16 lg:py-36
      max-w-[96rem];
  }
}
.fwig-box-story__image {
  @apply flex
    flex-shrink-0
    flex-col
    items-center justify-center
    gap-y-4
    w-full md:w-1/2 lg:w-1/3
    max-w-96
    self-center;

  > div {
    @apply flex-shrink-0
      relative
      w-full
      aspect-1
      rounded-3xl
      bg-clip-content
      overflow-hidden;

    > img {
      @apply absolute
      top-1/2
      left-1/2
      min-h-full
      min-w-[101%]
      object-cover;
      transform: translate(-50.05%, -50%);
    }
  }

  > p {
    @apply text-xs;
  }
}
.fwig-box-story__container {
  @apply flex flex-col gap-y-12;
}
.fwig-box-story__header {
  @apply text-4xl md:text-5xl font-bold;
}
.fwig-box-story__text {
  @apply md:text-lg;
}
.fwig-box-story__cta {
  @apply border-2 px-14 gap-x-4 border-white hover:border-ol-accent-1-100;
}
</style>
