<template>
  <section class="bg-ol-secondary-20 py-20 lg:py-28">
    <div>
      <container :is-grid="false" class="justify-center">
        <h2 class="text-2xl lg:text-4xl text-center mb-8 lg:mb-16">
          <slot name="section-heading">
            Call us now on
            <a :href="`tel:${phone_number}`" class="text-ol-pink">{{
              phone_print
            }}</a>
            to learn more about
          </slot>
        </h2>
      </container>

      <div
        class="flex flex-col md:flex-row md:max-w-[50%] lg:max-w-screen-lg mx-auto justify-around md:flex-wrap gap-3"
      >
        <BrochureProduct
          :key="product.title"
          :product="product"
          v-for="product in products"
        ></BrochureProduct>
      </div>
    </div>
  </section>
</template>

<script>
import Container from '../Core/Container.vue';

import BrochureProduct from './BrochureProduct.vue';

export default {
  components: {
    BrochureProduct,
    Container,
  },
  props: {
    products: { type: Array, required: true },
  },
  data() {
    return {
      phone_number: window.config.contact_phone_number,
      phone_print: window.config.contact_phone_print,
    };
  },
};
</script>
