<template>
  <section
    :class="`section-hero${useNew ? '-new' : ''} ${borderCss}`"
    data-testid="section-hero"
  >
    <div v-if="useNew" class="section-hero__container">
      <img v-if="image" :src="image" alt="hero-image" />
      <div class="section-hero__content">
        <h1
          v-if="title"
          class="section-hero__title"
          data-testid="section-hero-title"
        >
          {{ title }}
        </h1>
        <h2
          v-if="subTitle"
          class="section-hero__subtitle"
          data-testid="section-hero-subtitle"
        >
          {{ subTitle }}
        </h2>
        <slot name="body" />
      </div>
    </div>
    <template v-else>
      <div
        class="hero--image scale-150 lg:scale-125 h-full"
        :class="[backgroundPosition]"
        :style="style"
      />
      <Container class="py-12 z-10 relative h-full">
        <div
          class="col-span-8 text-ol-plum md:h-auto lg:col-span-7 lg:col-start-2 xl:col-span-6 xl:col-start-2"
        >
          <div
            class="flex flex-col items-start justify-center h-full gap-y-4 lg:gap-y-8"
          >
            <slot name="body" />

            <div v-if="showTrustpilot">
              <div
                class="trustpilot-widget"
                data-locale="en-GB"
                data-template-id="5419b732fbfb950b10de65e5"
                data-businessunit-id="657080ebfa8c7e8d395f33bd"
                data-style-height="24px"
                data-style-width="280px"
                data-theme="dark"
                data-font-family="Montserrat"
                data-text-color="#FFFEF3"
              >
                <a
                  href="https://uk.trustpilot.com/review/octopuslegacy.com"
                  target="_blank"
                  rel="noopener"
                  >Trustpilot</a
                >
              </div>
            </div>
          </div>
        </div>
      </Container>
    </template>
  </section>
</template>

<script>
import Container from '@components/Core/Container.vue';

export default {
  components: {
    Container,
  },
  props: {
    image: {
      type: String,
      default: '/theme/ol/bench-blur.jpg',
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subTitle: {
      type: String,
      required: false,
      default: '',
    },
    showTrustpilot: {
      type: Boolean,
      default: false,
      required: false,
    },
    useNew: {
      type: Boolean,
      default: false,
      required: false,
    },
    backgroundPosition: {
      type: String,
      default: 'bg-center',
      required: false,
    },
    borderCss: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    style() {
      return `background-image: url(${this.image});`;
    },
  },
};
</script>

<style lang="scss" scoped>
.hero--image {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.section-hero {
  @apply relative overflow-hidden;
}

.section-hero-new {
  @apply flex
    flex-col
    m-2 md:m-3
    p-0.5
    rounded-3xl;
}

.section-hero__container {
  // 1.5rem - 0.125rem (rounded-3xl - p-0.5) to get inner border-radius
  @apply relative w-full
    rounded-[1.375rem]
    bg-clip-content
    overflow-hidden;

  > img {
    @apply absolute top-1/2 left-1/2 min-h-full min-w-[101%] object-cover;
    transform: translate(-50.05%, -50%);
  }
}

.section-hero__content {
  @apply flex flex-col
    justify-center sm:items-center
    py-20 xs:py-28 sm:py-36 lg:py-40 2xl:py-48
    px-5 xs:px-8 sm:px-7 lg:px-10;
}

.section-hero__title {
  @apply font-ol-roobert
    leading-12
    text-[2.75rem] xs:text-4xl sm:text-5xl lg:text-6xl 2xl:text-7xl
    font-bold
    sm:text-center
    mb-[0.875rem] xs:mb-8 md:mb-6 lg:mb-8;
}

.section-hero__subtitle {
  @apply font-ol-roobert
  text-lg sm:text-xl md:text-lg lg:text-2xl
  sm:text-center;
}
</style>
<style lang="scss">
.section-hero__container * {
  @apply z-0;
}
</style>
