<template>
  <section class="contact-cards">
    <div class="flex flex-col gap-y-4">
      <h3 class="contact-cards__header" data-testid="contact-cards-header">
        Can't find what you're looking for?
      </h3>
      <p class="contact-cards__subheader" data-testid="contact-cards-subheader">
        We're here to help.
      </p>
    </div>
    <div class="contact-cards__container">
      <BoxWithIcon
        icon="ChatBubbleLeftRight"
        title="For Customer Support"
        data-testid="contact-cards-customer-support"
        class="contact-cards__card"
      >
        <div class="contact-cards__container__content">
          <p>
            Our friendly team are on-hand to help you with any questions,
            challenges or concerns. We know this stuff can be confusing.
          </p>
          <p>We're here to make it simple.</p>
          <div class="flex flex-col gap-y-2">
            <a href="javascript:Intercom('show');" class="contact-cards__link">
              Chat to us
            </a>
            <a :href="`mailto:${email}`" class="contact-cards__link">
              {{ email }}
            </a>
            <a :href="`tel:${phoneNumber}`" class="contact-cards__link">
              {{ phonePrint }}
            </a>
          </div>
        </div>
      </BoxWithIcon>
      <BoxWithIcon
        icon="Briefcase"
        icon-class="[&>g>path]:!stroke-white"
        title="Work With Us"
        data-testid="contact-cards-work-with-us"
        class="contact-cards__card"
      >
        <div class="contact-cards__container__content">
          <p>
            Are you a Charity, Funeral Director or Employer that's interested in
            partnering with us?
          </p>
          <p>Get in touch with our team to find out more.</p>
          <a :href="`mailto:${salesEmail}`" class="contact-cards__link">
            {{ salesEmail }}
          </a>
          <hr class="border-ol-dark-20 my-4" />
          <h4 class="text-lg font-bold">Looking to join our team?</h4>
          <p>
            Check out how you can help be a part of our mission here on our jobs
            page.
          </p>
          <a :href="route('careers')" class="contact-cards__link">
            Jobs page <Arrow class="h-3 mt-1" />
          </a>
        </div>
      </BoxWithIcon>
      <BoxWithIcon
        icon="Scale"
        icon-class="[&>g>path]:!stroke-white"
        title="Octopus Legal Services"
        data-testid="contact-cards-octopus-legal"
        class="contact-cards__card"
      >
        <div class="contact-cards__container__content">
          <p>
            For questions relating to probate or our probate services, please
            contact Octopus Legal Services.
          </p>
          <p>
            For anything else, you can contact our customer help and support.
          </p>
          <div class="flex flex-col gap-y-2">
            <a :href="`mailto:${probateEmail}`" class="contact-cards__link">
              {{ probateEmail }}
            </a>
            <a :href="`tel:${legalPhoneNumber}`" class="contact-cards__link">
              {{ legalPhonePrint }}
            </a>
          </div>
        </div>
      </BoxWithIcon>
    </div>
  </section>
</template>

<script>
import BoxWithIcon from '@components/Brochure/BoxWithIcon.vue';
import Arrow from '@components/Icon/Arrow.vue';
import { ref } from 'vue';

export default {
  components: {
    BoxWithIcon,
    Arrow,
  },
  setup() {
    return {
      phoneNumber: ref(window.config.contact_phone_number),
      phonePrint: ref(window.config.contact_phone_print),
      email: ref(window.config.contact_email),
      salesEmail: ref(window.config.sales_email),
      probateEmail: ref(window.config.legal_email),
      legalPhoneNumber: ref(window.config.legal_phone_number),
      legalPhonePrint: ref(window.config.legal_phone_print),
    };
  },
};
</script>

<style lang="scss" scoped>
.contact-cards {
  @apply flex
    flex-col
    items-center
    gap-y-12
    bg-ol-cream
    pt-32
    px-4 md:px-12;
}

.contact-cards__header {
  @apply text-center
    text-4xl
    font-bold;
}

.contact-cards__subheader {
  @apply text-center text-xl;
}

.contact-cards__container {
  @apply grid
    lg:grid-cols-2
    gap-6 lg:gap-8
    max-w-[76rem];
}

.contact-cards__card {
  @apply w-full;
}

.contact-cards__container__content {
  @apply flex
    flex-col
    gap-4
    lg:text-lg;
}

.contact-cards__link {
  @apply flex
    items-center
    gap-x-1
    w-fit
    text-xl
    text-ol-purple
    hover:text-ol-dark-ink-200
    transition-colors;
}
</style>
