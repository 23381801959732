<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <rect
      width="20.8333"
      height="20.8333"
      transform="translate(0.166504 0.0601807)"
      fill="#160535"
    />
    <path
      d="M3.42188 11.7789L12.5365 2.01331L10.5833 9.17476H17.7448L8.63021 18.9404L10.5833 11.7789H3.42188Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Bolt',
};
</script>
