<template>
  <section class="text-ol-dark-ink-200 font-bold flex flex-col">
    <h1 class="text-2xl md:text-4xl xl:text-5xl text-center mb-7">
      {{ heading }}
    </h1>
    <p
      class="text-base font-normal text-center md:text-xl max-w-[1000px] mx-auto"
    >
      {{ paragraph }}
    </p>
    <slot name="button"></slot>
  </section>
</template>

<script>
import Container from '@js/components/Core/Container.vue';

export default {
  name: 'HeadingWithDescription',
  components: {
    Container,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    paragraph: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
