<template>
  <section class="complaints-section" data-testid="complaints-section">
    <div class="complaints-content">
      <h4>Client feedback and complaints procedure</h4>
      <p>
        Sam founded Octopus Legacy on a mission to make life easier for the
        people left behind after loss, after his mum died suddenly in a car
        accident. We know the difference a plan makes and what it's like when
        there isn't one, which is why we're committed to providing the best and
        easiest service for you, our clients. We welcome your feedback, as it
        allows us to identify areas for improvement, and to address them for you
        and for other customers in the future.
      </p>
    </div>
    <div class="complaints-content">
      <h4>Raise a complaint</h4>
      <p>
        If you don't feel that our service has lived up to expectations, or you
        were dissatisfied with your experience we want to hear from you. Sharing
        your complaint allows us to work out what went wrong, put it right and
        avoid something similar happening again in the future. We will always
        respond directly to any complaints.
      </p>
      <div
        class="complaints-content__cards"
        data-testid="complaints-content-cards"
      >
        <BoxWithIcon
          title="How to raise a complaint"
          icon="InformationCircle"
          class="complaints-icon-box"
        >
          <ol type="1">
            <li>Share your complaint either by emailing or writing to us.</li>
            <li>Please include:</li>
            <ol type="A">
              <li>
                Your contact details so we can reply to your complaint
                including:
                <ol type="I">
                  <li>Your full name</li>
                  <li>Your email</li>
                  <li>Your address</li>
                  <li>The date and time of the issue</li>
                </ol>
              </li>
              <li>The date and time of the issue</li>
              <li>Any relevant supporting information</li>
            </ol>
          </ol>
        </BoxWithIcon>
        <BoxWithIcon
          title="Contact us via"
          icon="ChatBubbleLeftRight"
          class="complaints-icon-box"
        >
          <p>
            Email us: <a :href="`mailto:${legalEmail}`">{{ legalEmail }}</a>
          </p>
          <hr />
          <p class="font-bold mb-1">Write to us:</p>
          <p>
            Octopus Legal Services Ltd,<br />7th Floor, 33 Holborn,<br />
            London<br />
            EC1N 2HU
          </p>
        </BoxWithIcon>
      </div>
    </div>
    <div class="complaints-content" data-testid="complaints-content">
      <h4>Our complaints process</h4>
      <p>
        We take complaints incredibly seriously, which is why we've laid out a
        clear process so you know what you can expect from us.
      </p>
      <div>
        <p class="font-bold">We will:</p>
        <ol type="1">
          <li>
            Acknowledge your complaint: Within 3 business days we will:
            <ol type="A">
              <li>Confirm we've received the complaint</li>
              <li>
                Provide you with the contact details of your point of contact
                for this process
              </li>
              <li>
                Either address and resolve your complaint or inform you of the
                course of action we are taking to investigate and resolve your
                complaint and the expected timeline for this.
              </li>
            </ol>
          </li>
          <li>
            Investigate the complaint: We aim to do this within 21 days from the
            date of acknowledgement. The person handling the complaint will
            carry out the following in a fair and impartial manner:
            <ol type="A">
              <li>Review any relevant information or documentation</li>
              <li>
                Gather further information or clarify further details if
                required
              </li>
              <li>Speak with any relevant departments or staff</li>
              <li>Consider possible solutions to the complaint</li>
            </ol>
          </li>
          <li>
            Provide a written response aiming to resolve your complaint: We will
            do so within a reasonable time frame given the nature and complexity
            of the complaint (as an absolute maximum this will take 8 weeks from
            the date of acknowledging your complaint). This will include:
            <ol type="A">
              <li>A summary of the investigation.</li>
              <li>Our findings, conclusions and recommendations.</li>
              <li>
                Proposed plan to resolve the complaint or issue raised,
                including any changes we may make to our software or services to
                prevent similar complaints in the future
              </li>
              <li>Any compensation or refund we may offer.</li>
            </ol>
          </li>
          <li>
            Escalating complaints. If you are not satisfied with our response,
            you can escalate your complaint to a more senior member of staff.
            They will then review your complaint and the internal investigation,
            before providing a final response. If you're still not happy with
            the final response you can refer your complaint to the Legal
            Ombudsman, within 6 years of the event you're complaining about. The
            Legal Ombudsman is an independent body that provides a free service
            to resolve complaints about legal services providers. You can
            contact them:
            <ul data-testid="complaints-content-links">
              <li>
                Online:
                <a :href="`https://${ombudsman.website}`" target="_blank">{{
                  ombudsman.website
                }}</a>
              </li>
              <li>
                Over the phone:
                <a :href="`tel:${ombudsman.phone}`">{{ ombudsman.phone }}</a>
              </li>
              <li>
                Email:
                <a :href="`mailto:${ombudsman.email}`">{{ ombudsman.email }}</a>
              </li>
              <li>Post: Legal Ombudsman, PO Box 6167, Slough, SL1 0EH</li>
            </ul>
          </li>
        </ol>
      </div>
      <div>
        <p>We will also:</p>
        <ul>
          <li>
            <b>Keep strict records of any complaints and investigations:</b>
            These will be kept up to date and confidential, in order to help us
            to constantly improve.
          </li>
          <li>
            <b>Invite feedback on our review process:</b> To help us constantly
            improve.
          </li>
          <li>
            <b>Review our complaints process regularly:</b> To ensure it's
            up-to-date and is effective.
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import BoxWithIcon from '@components/Brochure/BoxWithIcon.vue';
import { ref } from 'vue';

export default {
  components: {
    BoxWithIcon,
  },
  setup() {
    return {
      legalEmail: ref(window.config.legal_email),
      ombudsman: {
        website: 'www.legalombudsman.org.uk',
        phone: '0300 555 0333',
        email: 'enquiries@legalombudsman.org.uk',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.complaints-section {
  @apply flex
    flex-col
    gap-y-8 lg:gap-y-14
    items-center
    bg-ol-cream
    pt-8 md:pt-16 xl:pt-36
    px-4 md:px-8 xl:px-32;

  a {
    @apply underline
      text-ol-primary hover:text-ol-dark-ink-200
      transition-colors;
  }

  > div > div {
    > ol,
    > ul {
      @apply ml-6;
    }
  }

  > div > div > ol {
    @apply list-decimal;
    > li {
      @apply mb-6;
      > ol {
        list-style: lower-alpha;
        > li {
          @apply ml-8 mt-2;
          > ol {
            list-style: lower-roman;
          }
        }
      }
      > ul {
        @apply list-disc ml-6 md:ml-8;
        > li {
          @apply mt-3 leading-6;
        }
      }
    }
  }

  > div > div > ul {
    @apply list-disc;
    > li {
      @apply mb-1;
    }
  }
}

.complaints-content {
  @apply flex
    flex-col
    gap-y-3 lg:gap-y-5
    w-full
    max-w-[76rem];

  > h4 {
    @apply text-3xl lg:text-4xl;
  }

  p,
  li {
    @apply text-lg lg:text-xl;
  }

  > div > p {
    @apply mb-2;
  }
}

.complaints-content__cards {
  @apply grid
    lg:grid-cols-2
    gap-4 sm:gap-5 md:gap-6 lg:gap-8
    pt-4;

  .complaints-icon-box {
    @apply w-full;

    p,
    li {
      @apply text-ol-ashes
        text-base sm:text-lg lg:text-xl;
    }

    ol {
      @apply ml-3 sm:ml-4;
    }

    > ol {
      @apply ml-5 sm:ml-6 list-decimal;
      > li {
        @apply mb-1;
      }
      ol {
        @apply ml-6 sm:ml-8;
      }
      > ol {
        list-style: lower-alpha;
        > li {
          @apply mt-1;
          > ol {
            list-style: lower-roman;
          }
        }
      }
    }

    > hr {
      @apply my-4 md:my-6 lg:my-8 border-ol-dark-20;
    }
  }
}
</style>
