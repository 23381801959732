<template>
  <section
    :class="`cta-section${useNew ? '-new' : ''}`"
    data-testid="cta-section"
  >
    <Container
      :is-grid="!useNew"
      :class="useNew ? 'cta-section-new__container' : 'gap-y-10'"
    >
      <section :class="`cta-section${useNew ? '-new' : ''}__inner`">
        <h3
          :class="`cta-section${useNew ? '-new' : ''}__heading`"
          data-testid="section-cta-title"
        >
          {{ sectionCta.heading }}
        </h3>
        <h4 :class="`cta-section${useNew ? '-new' : ''}__subheading`">
          {{ sectionCta.subheading }}
        </h4>
        <a
          :href="sectionCta.route"
          class="inline-block"
          :class="{ 'w-full md:w-fit': useNew }"
        >
          <CTA v-if="useNew" use-new>
            {{ sectionCta.buttonLabel }}
          </CTA>
          <ButtonRounded
            v-else
            :width="null"
            variant="sectionCta"
            class="cta-section__button"
          >
            <a :href="sectionCta.route">
              {{ sectionCta.buttonLabel }}
            </a>
          </ButtonRounded>
        </a>
      </section>
    </Container>
  </section>
</template>

<script>
import Container from '@components/Core/Container.vue';
import CTA from '@components/Button/Cta.vue';
import ButtonRounded from '@components/Button/ButtonRounded.vue';
import { useCtaDetails } from '../../composables/useCtaDetails';

export default {
  components: {
    Container,
    ButtonRounded,
    CTA,
  },
  props: {
    useNew: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const { sectionCta } = useCtaDetails();
    return { sectionCta };
  },
};
</script>
<style scoped>
.cta-section__button {
  @apply w-full md:w-fit;
}

.cta-section {
  @apply bg-ol-dark-ink-200 text-white py-20;
}

.cta-section__inner {
  @apply lg:col-start-1 col-span-8 lg:col-span-12;
}

.cta-section__heading {
  @apply text-white text-4xl 2xl:text-5xl mb-5;
}

.cta-section__subheading {
  @apply text-base 2xl:text-2xl mb-11;
}

.cta-section-new {
  @apply flex justify-center
    bg-ol-cream
    px-2 md:px-3
    lg:mb-28 xl:mb-36;
}

.cta-section-new__container {
  @apply rounded-2xl
    w-full max-w-[76rem]
    !bg-ol-secondary-20
    overflow-auto
    p-0;
}

.cta-section-new__heading {
  @apply text-ol-dark-ink-200
    text-3xl lg:text-4xl xl:text-[2.75rem]
    mb-6 xl:mb-7;
}

.cta-section-new__subheading {
  @apply text-ol-dark-ink-200
    xs:text-lg md:text-xl
    mb-10 lg:mb-14;
}

.cta-section-new__inner {
  @apply bg-ol-secondary-20
    px-5 xs:px-7 sm:px-10 md:px-7 lg:px-9 xl:px-[3.25rem]
    py-20 lg:p-12 xl:p-20;
}
</style>
