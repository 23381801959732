<template>
  <div class="flex flex-col gap-y-4 lg:order-0 text-base">
    <div
      v-for="feature in features"
      :key="feature.text"
      class="flex items-center gap-2"
      data-testid="brochure-hero-features"
    >
      <component :is="feature.svg" class="w-6" />
      <h4>{{ feature.text }}</h4>
    </div>
  </div>
</template>
<script>
import PoundSign from '@components/Icon/PoundSign.vue';
import Time from '@components/Icon/Time.vue';
import WhiteCheckmark from '@components/Icon/WhiteCheckmark.vue';

export default {
  components: { PoundSign, Time, WhiteCheckmark },
  props: {
    features: {
      type: Array,
    },
  },
};
</script>
