<template xmlns="http://www.w3.org/1999/html">
  <div>
    <BrochureHero
      :photos="heroSection.photos"
      :title="heroSection.title"
      :subtitle="heroSection.subtitle"
      :features="heroSection.features"
      order="reverse"
      :primaryButton="heroSection.primaryButton"
      :hideCarousel="true"
    ></BrochureHero>

    <BrouchureSteps :steps="stepsSection"></BrouchureSteps>
    <slot />
  </div>
</template>

<style scoped></style>

<script>
import BrochureHero from '@js/components/Brochure/BrochureHero.vue';
import Container from '@components/Core/Container.vue';
import Cta from '@components/Button/Cta.vue';
import ImageLazyLoad from '@components/ImageLazyLoad.vue';
import { ref } from 'vue';
import BrochureComparison from '@components/Brochure/BrochureComparison.vue';
import BrochureProducts from '@components/Brochure/BrochureProducts.vue';
import BrouchureSteps from '@components/Brochure/BrochureSteps.vue';

export default {
  name: 'WillWriting',
  components: {
    BrouchureSteps,
    BrochureProducts,
    BrochureComparison,
    BrochureHero,
    Container,
    Cta,
    ImageLazyLoad,
  },

  props: {
    ctaUrl: {
      type: String,
      default: 'will.charity-will.start',
    },
  },

  setup(props) {
    const heroSection = ref({
      title: 'Claim your free will',
      subtitle:
        'We’ve partnered with charities from across the UK to make sorting your will quick, easy, and free.',
      photos: [
        { name: 'water sacrifice', src: '/theme/ol/water-sacrifice.jpeg' },
        { name: 'fatherhood', src: '/theme/ol/boat-paddle.png' },
        { name: 'suz and grandad', src: '/theme/ol/Suz-great-grandad.jpeg' },
      ],
      features: [
        { svg: 'Time', text: 'Done in 15 minutes' },
        { svg: 'WhiteCheckmark', text: 'With expert support' },
        { svg: 'PoundSign', text: 'Free' },
      ],
      primaryButton: {
        text: "Let's get started",
        route: props.ctaUrl,
      },
    });

    const stepsSection = ref({
      title: 'Write your will in 3 simple steps',
      steps: [
        {
          svg: 'NumberOne',
          name: 'Answer a few simple questions',
          description:
            'We’ll help you write your will by asking questions to help work out what you want.',
        },
        {
          svg: 'NumberTwo',
          name: 'Complete your will',
          description:
            'A basic will is completed online and a bespoke will is completed with our in house team.',
        },
        {
          svg: 'NumberThree',
          name: 'Check and sign your will',
          description:
            'You can update your will whenever you like, for free in your first year, and only £10 a year after that.',
        },
      ],
    });

    return { heroSection, stepsSection };
  },
};
</script>
