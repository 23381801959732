<template>
  <svg fill="none" viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m20 35.5c8.2843 0 15-6.7157 15-15s-6.7157-15-15-15-15 6.7157-15 15 6.7157 15 15 15z"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
    <path
      d="m20 11.75v8.75h8.75"
      stroke="#fff"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="3"
    />
  </svg>
</template>
