<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <rect
      width="20.8333"
      height="20.8333"
      transform="translate(0.166504 0.0602112)"
      fill="#160535"
    />
    <path
      d="M14.4898 16.3363H6.67727M14.4898 16.3363C15.928 16.3363 17.0939 17.5022 17.0939 18.9404H4.0731C4.0731 17.5022 5.23903 16.3363 6.67727 16.3363M14.4898 16.3363V13.4066C14.4898 12.8672 14.0525 12.43 13.5132 12.43H12.7566M6.67727 16.3363V13.4066C6.67727 12.8672 7.11449 12.43 7.65383 12.43H8.41046M12.7566 12.43H8.41046M12.7566 12.43C12.2815 11.6087 11.9806 10.6739 11.9046 9.67629M8.41046 12.43C8.88557 11.6087 9.18641 10.6739 9.26241 9.67629M4.72415 3.7376C3.87146 3.8616 3.02741 4.01225 2.19287 4.18869C2.58377 6.50421 4.50273 8.30051 6.87662 8.50444M4.72415 3.7376V3.96646C4.72415 5.79613 5.56278 7.42993 6.87662 8.50444M4.72415 3.7376V2.4221C6.63858 2.15267 8.59478 2.01334 10.5835 2.01334C12.5723 2.01334 14.5285 2.15267 16.4429 2.4221V3.7376M6.87662 8.50444C7.56028 9.06356 8.37261 9.47125 9.26241 9.67629M16.4429 3.7376V3.96646C16.4429 5.79613 15.6043 7.42993 14.2904 8.50444M16.4429 3.7376C17.2956 3.8616 18.1396 4.01226 18.9742 4.18869C18.5833 6.50422 16.6643 8.30051 14.2904 8.50444M14.2904 8.50444C13.6068 9.06356 12.7944 9.47125 11.9046 9.67629M11.9046 9.67629C11.48 9.77413 11.0378 9.82584 10.5835 9.82584C10.1292 9.82584 9.687 9.77413 9.26241 9.67629"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'Trophy',
};
</script>
