<template>
  <div
    class="p-8 text-ol-dark-ink-200 border-2 rounded-2xl w-full lg:w-[355px] xl:w-[450px] 2xl:w-[605px]"
  >
    <div class="flex items-center mb-6 gap-3 md:gap-4 lg:gap-6">
      <div class="w-10 h-10 bg-ol-dark-ink-200 rounded-full flex shrink-0">
        <component :is="icon" class="my-auto mx-auto" :class="iconClass" />
      </div>
      <h1 class="font-bold text-xl my-auto xl:text-2xl">
        {{ title }}
      </h1>
    </div>
    <p class="text-ol-ashes font-normal xl:text-lg">
      {{ paragraph }}
    </p>
    <slot />
  </div>
</template>

<script>
import BankNotes from '@components/Icon/BankNotes.vue';
import LifeBuoy from '@components/Icon/LifeBuoy.vue';
import Bolt from '@components/Icon/Bolt.vue';
import Users from '@components/Icon/Users.vue';
import HandRaised from '@components/Icon/HandRaised.vue';
import Trophy from '@components/Icon/Trophy.vue';
import Cake from '@components/Icon/Cake.vue';
import Ticket from '@components/Icon/Ticket.vue';
import Sparkles from '@components/Icon/Sparkles.vue';
import RocketLaunch from '@components/Icon/RocketLaunch.vue';
import ChatBubbleLeftRight from '@components/Icon/ChatBubbleLeftRight.vue';
import Briefcase from '@components/Icon/Briefcase.vue';
import Scale from '@components/Icon/Scale.vue';
import InformationCircle from '@components/Icon/InformationCircle.vue';

export default {
  name: 'BoxWithIcon',
  components: {
    RocketLaunch,
    BankNotes,
    LifeBuoy,
    Bolt,
    Users,
    HandRaised,
    Trophy,
    Cake,
    Ticket,
    Sparkles,
    ChatBubbleLeftRight,
    Briefcase,
    Scale,
    InformationCircle,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    paragraph: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped></style>
