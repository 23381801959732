<template>
  <div class="service-card">
    <div class="flex flex-col gap-y-4">
      <h5
        class="text-2xl sm:text-3xl font-bold"
        data-testid="service-card-header"
      >
        {{ header }}
      </h5>
      <p class="sm:text-xl" data-testid="service-card-subheader">
        {{ subHeader }}
      </p>
    </div>
    <hr />
    <div class="flex flex-col gap-y-4 flex-grow">
      <h6 class="text-xl font-bold">WE WILL</h6>
      <ul class="flex flex-col gap-y-4" data-testid="service-card-bullets">
        <li
          v-for="(point, index) in bulletPoints"
          :key="index"
          class="flex items-center gap-x-3 text-sm lg:text-lg"
        >
          <CheckmarkStandalone class="w-5 sm:w-6 flex-shrink-0 fill-black" />
          {{ point }}
        </li>
      </ul>
    </div>
    <hr />
    <h2 class="text-xl font-bold">FIXED FEE PRICING</h2>
    <div
      class="flex flex-wrap gap-y-5 gap-x-14 mt-5 mb-7"
      data-testid="service-card-prices"
    >
      <h6 v-for="(pricing, index) in prices" :key="index" class="flex flex-col">
        <span class="text-xs">
          {{ pricing.title }}
        </span>
        <span class="text-5xl font-medium">
          £{{ pricing.value.toLocaleString() }}
        </span>
      </h6>
    </div>
    <p
      class="text-xs text-dark-pewter-50 mb-9"
      data-testid="service-card-fineprint"
    >
      {{ finePrint }}
    </p>
    <div class="flex flex-col sm:flex-row gap-4">
      <a
        :href="route('probate.form', { typeform, utmParams })"
        class="w-full sm:w-fit"
      >
        <CTA use-new>{{ ctaText }}</CTA>
      </a>
      <a
        v-if="linkToPrices"
        :href="linkToPrices"
        class="service-card__example-pricing"
      >
        Example Pricing
      </a>
    </div>
  </div>
</template>

<script>
import CheckmarkStandalone from '@components/Icon/CheckmarkStandalone.vue';
import CTA from '@components/Button/Cta.vue';

export default {
  components: {
    CheckmarkStandalone,
    CTA,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    subHeader: {
      type: String,
      required: true,
    },
    bulletPoints: {
      type: Array,
      required: true,
    },
    prices: {
      type: Array,
      required: true,
    },
    finePrint: {
      type: String,
      required: true,
    },
    typeform: {
      type: String,
      required: true,
    },
    utmParams: {
      type: String,
      required: true,
    },
    ctaText: {
      type: String,
      required: true,
    },
    linkToPrices: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.service-card {
  @apply flex
    xl:flex-1
    flex-col
    bg-white
    text-ol-plum
    py-8 sm:py-14
    px-5 sm:px-11
    w-full
    xl:w-1/2
    rounded-2xl;

  > hr {
    @apply border-ol-dark-10 my-9;
  }
}

.service-card__example-pricing {
  @apply w-full sm:w-fit
    border-2
    rounded-full
    text-base
    text-center
    !leading-none
    py-4
    px-7
    hover:bg-ol-accent-1-100
    hover:border-ol-accent-1-100
    hover:text-white
    transition-colors;
}
</style>
