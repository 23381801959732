<template>
  <div class="rounded-xl h-full">
    <a href="#" target="_blank" class="flex flex-col justify-between h-full">
      <div class="px-5 pt-8 pb-6 flex flex-col justify-between h-full gap-6">
        <p
          class="text-[24px] leading-[108%] text-ol-plum font-medium"
          v-text="displayReview"
        />
      </div>
      <div v-if="charity" class="flex items-center justify-between px-5 py-6">
        <img
          class="h-10"
          :src="`theme/img/charities/${charity}.svg`"
          :alt="charity"
        />
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    review: {
      type: String,
      required: true,
      default: 'Body goes here...',
    },
    charity: {
      type: String,
      required: false,
    },
  },
  computed: {
    displayReview() {
      return `“${this.review}”`;
    },
  },
};
</script>
