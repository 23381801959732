<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
  >
    <g clip-path="url(#clip0_6043_6394)">
      <rect
        width="20.8333"
        height="20.8333"
        transform="translate(0.166504 0.0601807)"
        fill="#160535"
      />
      <path
        d="M13.6999 12.5338C13.7905 12.9185 13.8384 13.3197 13.8384 13.7321C13.8384 16.6085 11.5065 18.9404 8.63005 18.9404V14.774M13.6999 12.5338C16.9412 10.1653 19.0467 6.33513 19.0467 2.01331C14.7251 2.01349 10.8952 4.11909 8.52694 7.36031M13.6999 12.5338C12.2167 13.6176 10.4958 14.3953 8.63005 14.774M8.52694 7.36031C8.142 7.26962 7.74059 7.22164 7.32796 7.22164C4.45148 7.22164 2.11963 9.55349 2.11963 12.43H6.28692M8.52694 7.36031C7.44329 8.8434 6.66564 10.5643 6.28692 12.43M8.63005 14.774C8.54028 14.7922 8.45018 14.8095 8.35975 14.8259C7.5725 14.2017 6.85913 13.4883 6.2349 12.701C6.25131 12.6104 6.26865 12.52 6.28692 12.43M4.34351 14.5053C3.38884 15.2174 2.77067 16.3557 2.77067 17.6383C2.77067 17.8439 2.78656 18.0458 2.81717 18.2429C3.0142 18.2735 3.2161 18.2893 3.42171 18.2893C4.70433 18.2893 5.84257 17.6712 6.55474 16.7165M14.4894 7.87268C14.4894 8.5918 13.9065 9.17476 13.1873 9.17476C12.4682 9.17476 11.8853 8.5918 11.8853 7.87268C11.8853 7.15356 12.4682 6.5706 13.1873 6.5706C13.9065 6.5706 14.4894 7.15356 14.4894 7.87268Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_6043_6394">
        <rect
          width="20.8333"
          height="20.8333"
          fill="white"
          transform="translate(0.166504 0.0601807)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'RocketLaunch',
};
</script>
