<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="26"
    viewBox="0 0 25 26"
    fill="none"
  >
    <rect
      width="25"
      height="25"
      transform="translate(0 0.685547)"
      fill="#160535"
    />
    <path
      d="M11.7188 12.4043L11.762 12.3827C12.359 12.0842 13.0312 12.6234 12.8693 13.2709L12.1307 16.2252C11.9688 16.8727 12.641 17.4119 13.238 17.1134L13.2812 17.0918M21.875 13.1855C21.875 18.3632 17.6777 22.5605 12.5 22.5605C7.32233 22.5605 3.125 18.3632 3.125 13.1855C3.125 8.00788 7.32233 3.81055 12.5 3.81055C17.6777 3.81055 21.875 8.00788 21.875 13.1855ZM12.5 9.2793H12.5078V9.28711H12.5V9.2793Z"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
