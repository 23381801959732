<template>
  <section class="below-fold">
    <h3 class="below-fold__text" data-testid="below-fold-text">
      <slot />
    </h3>
  </section>
</template>

<style lang="scss" scoped>
.below-fold {
  @apply flex
    justify-center
    bg-ol-cream
    w-full
    px-3 xs:px-8 sm:px-20 lg:px-36 xl:px-60
    py-20 xs:py-28 lg:py-40;
}

.below-fold__text {
  @apply text-center
    text-xl xs:text-2xl lg:text-3xl xl:text-4xl
    max-w-[70rem];
}
</style>
