<template>
  <section class="need-probate">
    <div class="need-probate__container">
      <h3 class="need-probate__header" data-testid="need-probate-header">
        Do you need <strong>probate?</strong>
      </h3>
      <div class="need-probate__text" data-testid="need-probate-text">
        <h4>
          Probate is the legal process of dealing with someone's property, money
          and possessions after they die. Probate is only required in around 50%
          of cases.
        </h4>
        <h4>
          Unsure if you need probate? Answer a few simple questions or call us
          now on
          <a :href="`tel:${phoneNumber}`" class="text-ol-dark font-bold">
            {{ phonePrint }}
          </a>
          to speak to a probate specialist.
        </h4>
        <div class="need-probate__buttons" data-testid="need-probate-buttons">
          <a :href="route('probate.lead.quiz')">
            <CTA class="need-probate__cta" use-new>Take the quiz</CTA>
          </a>
          <a :href="`tel:${phoneNumber}`" class="need-probate__call-us">
            Call us
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CTA from '@components/Button/Cta.vue';
import { ref } from 'vue';

export default {
  components: {
    CTA,
  },
  setup() {
    const phoneNumber = ref(window.config.probate_phone_number);
    const phonePrint = ref(window.config.probate_phone_print);

    return {
      phoneNumber,
      phonePrint,
    };
  },
};
</script>

<style lang="scss" scoped>
.need-probate {
  @apply flex
  justify-center items-center
  bg-ol-cream
  m-2 md:m-3 lg:m-16
  lg:mb-36;
}

.need-probate__container {
  @apply flex
    flex-col xl:flex-row
    justify-between
    gap-y-10 xl:gap-y-0
    md:gap-x-32
    px-5 xs:px-8 lg:px-16 xl:px-20
    py-14 xl:py-20
    rounded-2xl
    lg:max-w-[72rem]
    bg-ol-secondary-20
    text-ol-dark-ink-200
    font-ol-roobert;
}

.need-probate__header {
  @apply text-3xl lg:text-4xl xl:text-5xl
    md:w-[42rem];
}

.need-probate__text {
  @apply flex
    flex-col
    gap-y-6
    text-lg md:text-base lg:text-xl;
}

.need-probate__buttons {
  @apply flex
  flex-wrap
    gap-4
    mt-6;

  > a {
    @apply w-full sm:w-fit;
  }
}

.need-probate__call-us {
  @apply border-2
    rounded-full
    text-base lg:text-lg
    text-center
    !leading-none
    py-4
    px-8
    hover:bg-ol-accent-1-100
    hover:border-ol-accent-1-100
    hover:text-white
    transition-colors;
}

.need-probate__cta {
  @apply w-full sm:w-fit;
}
</style>
